<template>
	<div class="session-row-item d-flex">
		<v-progress-circular
			class="progressCircle"
			:rotate="-90"
			:class="`${verticalLineBackground} ${isLast === false && 'verticalLine'}`"
			:width="7"
			:size="70"
			:value="isSessionInProgress ? 0 : latestScore"
			:color="progressColor"
		>
			<!-- <v-icon v-if="session.position_list === 10" color="black" class="hideWhenHover">🏁</v-icon> -->
			<v-icon v-if="isSessionInProgress" color="#0000003d">mdi-timer-sand</v-icon>
			<v-icon v-else-if="latestScore && latestScore >= 80" color="#34e5c8" class="hideWhenHover">
				mdi-checkbox-marked-circle
			</v-icon>
			<v-icon v-else-if="latestScore && latestScore < 80" color="#ff9800" class="hideWhenHover">
				mdi-alert-circle
			</v-icon>
			<v-icon v-else-if="session.status === SessionStatus.LOCKED" color="#0000003d">mdi-lock-outline</v-icon>
			<v-icon v-else-if="session.status === SessionStatus.OPEN" color="#0000003d">mdi-lock-open-outline</v-icon>
			<v-icon v-else-if="latestScore === 0" color="#0000003d">mdi-lock-open-outline</v-icon>

			<v-icon
				v-if="latestScore && isSessionInProgress == false"
				class="scoreLabel"
				size="16"
				:color="latestScore && latestScore >= 80 ? '#34e5c8' : '#ff9800'"
			>
				{{ latestScore }}%
			</v-icon>
		</v-progress-circular>
		<div class="infoContainer">
			<div class="infoSubContainer" @click="doSession(session._id)">
				<v-toolbar-title class="title">{{ session.title }}</v-toolbar-title>
				<v-btn
					v-if="allowDoingSession"
					icon
					color="#2dc7ae"
					class="mr-4 playIconExercise"
					@click="doSession(session._id)"
				>
					<v-icon>fas fa-play</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { SessionStatus } from '@/constants/session.js';
import { checkIfTodayExerciceIsDone } from '@/utils/exercise.js';
import { getStartOfNextWednesday, isPastGivenTime } from '@/utils/time.js';

export default {
	name: 'SessionRowItem',
	data() {
		return {
			SessionStatus,
			latestScore: null,
		};
	},
	props: {
		session: Object,
		isLast: Boolean,
		formation: Object,
		passedDateOfLastPassedSession: String,
	},
	created() {
		this.latestScore = this.session.score;
	},
	computed: {
		progressColor() {
			if (this.latestScore == null) return '#d8d8d8';
			if (this.latestScore >= 80) return '#34e5c8';
			return '#ff9800';
		},
		verticalLineBackground() {
			if (this.isSessionInProgress) return 'disabledColor';
			if (this.latestScore && this.latestScore >= 80) return 'passedColor';
			if (this.latestScore && this.latestScore < 80) return 'notPassedColor';
			if (this.isLast) return '';
			else return 'disabledColor';
		},
		allowDoingSession() {
			if (
				[SessionStatus.ONGOING, SessionStatus.OPEN].includes(this.session.status) &&
				this.formation.formation_finished
			)
				return false;
			if (this.session.status === SessionStatus.LOCKED) return false;
			if ([SessionStatus.ONGOING, SessionStatus.PASSED].includes(this.session.status)) return true;
			if (checkIfTodayExerciceIsDone(this.formation) === false) return true;
			if (this.formation.last_exercice_date == null) return true;
			if (this.passedDateOfLastPassedSession == null) return true; // First session of category, show user to do
			const startOfNextWedSinceLastPassedTime = getStartOfNextWednesday(this.passedDateOfLastPassedSession);
			if (isPastGivenTime(startOfNextWedSinceLastPassedTime)) return true;
			return this.formation.infinity_exercice;
		},
		isSessionInProgress() {
			const TRACK_PROGRESS_CONST = {
				case1: 'has-track-reponse-but-not-complete-any-quiz-or-review',
				case2: 'finished-all-quiz-blocks',
				case3: 'finished-all-review-blocks',
				case4: 'never-do-this-session',
			};
			let status = null;
			if (this.session == null || this.session.sessionBlocks == null) return status;

			let totalQuizBlock = 0;
			let totalReviewBlock = 0;
			let numQuizBlockCompleted = 0;
			let numReviewBlockCompleted = 0;

			let hasTrackResponse = false;

			this.session.sessionBlocks.forEach((sessionBlock) => {
				let totalTrackOfBlock = 0;
				let countTrackHasReponse = 0;

				//count block type
				if (sessionBlock.type === 'quiz') totalQuizBlock += 1;
				else if (sessionBlock.type === 'review') totalReviewBlock += 1;

				if (sessionBlock.questionnaires == null) return; // Guard condition

				sessionBlock.questionnaires.forEach((questionnaire) => {
					if (questionnaire.tracks == null) return; // Guard condition
					questionnaire.tracks.forEach((track) => {
						totalTrackOfBlock += 1;
						if (track.trackResponse != null) {
							hasTrackResponse = true;
							countTrackHasReponse += 1;
						}
					});
				});

				if (totalTrackOfBlock !== 0 && countTrackHasReponse !== 0) {
					if (totalTrackOfBlock === countTrackHasReponse && sessionBlock.type === 'quiz') numQuizBlockCompleted += 1;
					else if (totalTrackOfBlock === countTrackHasReponse && sessionBlock.type === 'review')
						numReviewBlockCompleted += 1;
				}
			});

			if (totalQuizBlock === 0 && totalReviewBlock === 0) return 'session-has-no-quiz-no-review';

			if (hasTrackResponse == false) {
				status = TRACK_PROGRESS_CONST.case4;
			} else if (numQuizBlockCompleted === totalQuizBlock && numReviewBlockCompleted !== totalReviewBlock) {
				status = TRACK_PROGRESS_CONST.case2;
			} else if (numReviewBlockCompleted === totalReviewBlock && numQuizBlockCompleted !== totalQuizBlock) {
				status = TRACK_PROGRESS_CONST.case3;
			} else if (
				hasTrackResponse &&
				numQuizBlockCompleted < totalQuizBlock &&
				numReviewBlockCompleted < totalReviewBlock
			) {
				status = TRACK_PROGRESS_CONST.case1;
			}

			return [TRACK_PROGRESS_CONST.case1, TRACK_PROGRESS_CONST.case2, TRACK_PROGRESS_CONST.case3].includes(status);
		},
	},
	methods: {
		async doSession(sessionId) {
			if (this.allowDoingSession == false) return;
			this.loading = true;
			if (this.session.score && process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('dashboard-profile-replaySession');
			}
			this.$router
				.push({
					name: 'SessionDetailPage',
					params: { formationId: this.formation._id, sessionId: sessionId },
				})
				.catch(() => {}); // Workaround vue router error
		},
	},
};
</script>

<style lang="scss" scoped>
.session-row-item {
	margin-bottom: 25px;

	.infoContainer {
		width: 100%;
		padding: 5px 0;
	}

	.infoSubContainer {
		padding: 5px 0;
		border: 2px solid white;
		border-radius: 0px 4px 4px 0px;
		border-left: none;
		transform: translate(-7px, 5px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0 -5px 7px -5px #8189a933, 5px 0 7px -5px #8189a933, 0 5px 7px -5px #8189a933;
		cursor: pointer;
	}

	.infoSubContainer:hover {
		color: #34e5c8;
		transition: color 200ms linear;
		-webkit-transition: color 200ms linear;
		-ms-transition: color 200ms linear;
		cursor: pointer;
	}

	.infoSubContainer {
		&:hover {
			color: #34e5c8;
			transition: color 200ms linear;
			-webkit-transition: color 200ms linear;
			-ms-transition: color 200ms linear;
			cursor: pointer;

			.playIconExercise {
				transform: rotate(360deg);
				-webkit-transform: rotate(360deg);
			}
		}
	}

	.progressCircle {
		background-color: white;
		position: relative;
		:hover {
			.scoreLabel {
				display: block;
				font-style: normal;
				font-weight: bold;
			}
			.hideWhenHover {
				display: none;
			}
		}
	}

	.verticalLine {
		::after {
			z-index: 1;
			content: '';
			position: absolute;
			width: 5px;
			height: 34px;
			top: 94%;
		}

		@media screen and (max-width: 500px) {
			::after {
				height: 37px;
				top: 91%;
			}
		}
		@media screen and (max-width: 400px) {
			::after {
				height: 37px;
				top: 92%;
			}
		}
	}
	.verticalLine.disabledColor {
		::after {
			background-color: #d8d8d8;
		}
	}
	.verticalLine.passedColor {
		::after {
			background-color: #34e5c8;
		}
	}
	.verticalLine.notPassedColor {
		::after {
			background-color: #ff9800;
		}
	}
	.title {
		margin-left: 20px;
	}
	.scoreLabel {
		display: none;
	}
}
</style>
