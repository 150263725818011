<template>
	<div>
		<div ref="exEketelonItem" class="invisible">
			<SessionRowItem
				:session="{ _id: 'sketelon', name: 'sletelon', status: 'locked', usersScores: [] }"
				:isLast="true"
				:formation="formation"
			/>
		</div>
		<template v-for="(session, index) in openOngoingAndPassedSessions">
			<SessionRowItem
				v-if="hideSession(session) === false"
				:key="session._id"
				:session="session"
				:isLast="index === openOngoingAndPassedSessions.length - 1"
				:formation="formation"
				:passedDateOfLastPassedSession="passedDateOfLastPassedSession"
			/>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import SessionRowItem from '@/components/dashboard/profile/sessions/SessionRowItem.vue';
import { SessionStatus } from '@/constants/session.js';
import { setWidthForExerciseItems } from '@/utils/css-util.js';
import { getStartOfNextWednesday, isPastGivenTime } from '@/utils/time.js';

export default {
	name: 'ListSessions',
	components: { SessionRowItem },
	data() {
		return { openOngoingAndPassedSessions: [], passedDateOfLastPassedSession: null };
	},
	props: { sessions: Array },
	created() {
		const sortedDescPassedEx = this.sessions
			.filter((session) => session.status === SessionStatus.PASSED)
			.sort((a, b) => b.position - a.position);
		if (sortedDescPassedEx[0]) this.passedDateOfLastPassedSession = sortedDescPassedEx[0].passedDate;
		const openAndOngoing = this.sessions.filter((session) =>
			[SessionStatus.OPEN, SessionStatus.ONGOING].includes(session.status)
		);

		this.openOngoingAndPassedSessions = [...openAndOngoing, ...sortedDescPassedEx];
	},
	mounted() {
		this.$nextTick(() => {
			setWidthForExerciseItems(); // Run after the rendering is completed
			const element = this.$refs.exEketelonItem;
			if (element) element.parentNode.removeChild(element);
		});
	},
	computed: {
		...mapGetters('profile', { formation: 'formation' }),
	},
	methods: {
		canDoSessionSinceLastPassedTime(lastPassedTime) {
			const startOfNextWedSinceLastPassedTime = getStartOfNextWednesday(lastPassedTime);
			return isPastGivenTime(startOfNextWedSinceLastPassedTime);
		},
		hideSession(session) {
			if (session.status === 'passed') return false; // Always show passed session
			if (this.formation.infinity_exercice === true) return false; // Infinity exercise user can always see next session
			if (this.passedDateOfLastPassedSession == null) return false; // First session of category, show user to do

			return !this.canDoSessionSinceLastPassedTime(this.passedDateOfLastPassedSession);
		},
	},
};
</script>

<style lang="scss" scoped></style>
