import { render, staticRenderFns } from "./RedactionChapterDetail.vue?vue&type=template&id=724d87ca&scoped=true&"
import script from "./RedactionChapterDetail.vue?vue&type=script&lang=js&"
export * from "./RedactionChapterDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724d87ca",
  null
  
)

export default component.exports